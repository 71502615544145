// import axios from '../Axios/index';
import ApiService from "../Services/Api/ApiService";




export const FETCH_PSYCHICS_REQUEST = 'FETCH_PSYCHICS_REQUEST';
export const FETCH_PSYCHICS_SUCCESS = 'FETCH_PSYCHICS_SUCCESS';
export const FETCH_PSYCHICS_FAILURE = 'FETCH_PSYCHICS_FAILURE';
export const FETCH_PSYCHICS_CLEAR = 'FETCH_PSYCHICS_CLEAR';
export const FETCH_PSYCHICS_REFRESH_UPDATE = 'FETCH_PSYCHICS_REFRESH_UPDATE';


export const STORE_FILLTER_SETTING = "STORE_FILLTER_SETTING"
export const STORE_FILLTER_SETTING_CLEAR = "STORE_FILLTER_SETTING_CLEAR"



export const storeTheFillterSetting = (data) => ({
  type: STORE_FILLTER_SETTING,
  payload:data
})

export const storeTheFillterSettingclear = () => ({
  type: STORE_FILLTER_SETTING_CLEAR,
})



export const fetchPsychicsRequest = () => ({
  type: FETCH_PSYCHICS_REQUEST,
});

export const fetchPsychicsSuccess = (data) => ({
  type: FETCH_PSYCHICS_SUCCESS,
  payload: data,
});

export const fetchPsychicsFailure = (error) => ({
  type: FETCH_PSYCHICS_FAILURE,
  payload: error,
});

export const fetchPsychicsClear = (data) => ({
  type: FETCH_PSYCHICS_CLEAR,
});

export const fetchPsychicRefreshWithUpdate = (data) => ({
  type: FETCH_PSYCHICS_REFRESH_UPDATE,
  payload: data,
});

//reels

export const FETCH_PSYCHICS_REELS_REQUEST = 'FETCH_PSYCHICS_REELS_REQUEST';
export const FETCH_PSYCHICS_REELS_SUCCESS = 'FETCH_PSYCHICS_REELS_SUCCESS';
export const FETCH_PSYCHICS_REELS_FAILURE = 'FETCH_PSYCHICS_REELS_FAILURE';
export const FETCH_PSYCHICS_REELS_CLEAR = 'FETCH_PSYCHICS_REELS_CLEAR';
export const FETCH_PSYCHICS_REELS_REFRESH_UPDATE = 'FETCH_PSYCHICS_REELS_REFRESH_UPDATE';



export const fetchPsychicsReelRequest = () => ({
  type: FETCH_PSYCHICS_REELS_REQUEST,
});

export const fetchPsychicsReelSuccess = (data) => ({
  type: FETCH_PSYCHICS_REELS_SUCCESS,
  payload: data,
});

export const fetchPsychicReelsRefreshWithUpdate = (data) => ({
  type: FETCH_PSYCHICS_REELS_REFRESH_UPDATE,
  payload: data,
});

export const fetchPsychicsReelFailure = (error) => ({
  type: FETCH_PSYCHICS_REELS_FAILURE,
  payload: error,
});

export const fetchPsychicsReelClear = (data) => ({
  type: FETCH_PSYCHICS_REELS_CLEAR,
});


// export const fetchPsychics = () => {
//   return async (dispatch) => {
//     dispatch(fetchPsychicsRequest());
//   }
// }
