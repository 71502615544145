import {
    USER_INFO_SUCCESS,
    USER_INFO_REMOVE_SUCCESS,
    USER_INFO_FAILURE,
    USER_INFO_REQUEST,
    PROFILE_TYPE_UPDATE,
    RECENT_CHAT_LIST_TAB_UPDATE,
    UPDATE_IMPERSONATING_USER_PROFILE,
} from "../Actions/Constants";
import { CONSUMER, AGENTLOGIN } from "../Helpers/Constants";

const initialState = {
    loading: false,
    userInfo: {
        type:null,
        code:null
    },
    isLoggedIn: false,
    error: '',
    profileType: 1,
    recentListType: 1,
    userRole: CONSUMER
};

export const UserInformationReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_INFO_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: action.payload,
                isLoggedIn: true,
                error: '',
                userRole: action.payload?.userRole ? action.payload?.userRole : CONSUMER,
                loading: false,
            };
        case USER_INFO_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,

            };
        case USER_INFO_REMOVE_SUCCESS:
            return {
                ...state,
                isLoggedIn:false,
                userInfo:{},
                error: '',
                userRole: CONSUMER,
                loading: false,
            }
        case PROFILE_TYPE_UPDATE:
            return {
                ...state,
                profileType: action.payload
            };
        case RECENT_CHAT_LIST_TAB_UPDATE:
            return {
                ...state,
                recentListType: action.payload
            };
        case UPDATE_IMPERSONATING_USER_PROFILE:
            console.log("UPDATE_IMPERSONATING_USER_PROFILE - action.payload:", action.payload);
            return {
                ...state,
                userInfo: action.payload,
                isLoggedIn: true,
                error: '',
                userRole: AGENTLOGIN,
                loading: false,
            };
        default:
            return state;
    }
};
