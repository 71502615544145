import { toast } from 'react-toastify';

export const internetFailedStatus = {status: 12163, message: 'Internet issue'};

export const handleApiFailedError = (error) => {
    if(error){
        const stringError = typeof error === 'object' && error !== null ? JSON.stringify(error) : String(error);
        if((error?.status && [401, internetFailedStatus.status].indexOf(error?.status) > -1) || (typeof stringError?.includes === 'function' && stringError?.includes(401))) return;
        const message = error?.message || 'Something went wrong. Please try again later.';
        toast.error(message);
    }
}
