import Store from '../../Store';
import IndexedDb from '../IndexedDb';
import { GroupsMemberListAction, GroupsMemberParticipantsListAction } from '../../Actions/GroupsAction';
import { getUserIdFromJid, isEmojiCharsOnly } from '../Utility';
import { isLocalUser } from './User';
const indexedDb = new IndexedDb()

export const setGroupParticipants = (res) => {
    Store.dispatch(GroupsMemberListAction(res));
}

export const setGroupParticipantsByGroupId = (groupId, participantsList) => {
    Store.dispatch(GroupsMemberParticipantsListAction({
        "groupId": groupId,
        "participantsList": participantsList,
    }));
}

/**
 * Get the group data from groups list
 * @param {*} groupId
 */
export const getGroupData = (groupId = "") => {
    const { data: groupData } = Store.getState().groupsData;
    return groupData && groupData.find((obj) => groupId.indexOf(obj.groupId) > -1);
}

/**
 * Check Given user exist in group or not
 */
export const isUserExistInGroup = (userId, groupUsers) => {
    if(!userId || (groupUsers && !Array.isArray(groupUsers))) return false;

    if(!groupUsers){
        const { groupsMemberListData: { data: { participants } = {} } = {} } = Store.getState();
        if(!participants) return false;
        groupUsers = participants;
    }
    return groupUsers.some(user => user.userId === userId);
}

/**
 * Check Given user exist in group or not
 */
 export const getUserFromGroup = (userId, groupUsers) => {
    if (!userId || (groupUsers && !Array.isArray(groupUsers))) return false;
    userId = getUserIdFromJid(userId);
    if (!groupUsers) {
      const { groupsMemberListData: { data: { participants } = {} } = {} } = Store.getState();
      if (!participants) return false;
      groupUsers = participants;
    }
    return groupUsers.find((user) => user.userId === userId);
  };

  export const getSortedGroupParticipants = (participants) => {
    if(!participants) return [];
    const localUserObj = [];
    let adminUserWithName = [];
    let adminUserWithoutName = [];
    let adminUserWithEmojisOnly = [];
    let userWithName = [];
    let userWithoutName = [];
    let userWithEmojisOnly = [];
    participants.forEach((participant) => {
      if(isLocalUser(participant.userId)){
        localUserObj.push(participant);
        return;
      }

      if(participant.userType === 'o' && participant.isFriend){
        adminUserWithName.push(participant);
        return;
      }

      if(participant.userType === 'o' && !participant.isFriend && isEmojiCharsOnly(participant?.userProfile?.nickName)){
        adminUserWithEmojisOnly.push(participant);
        return;
      }

      if(participant.userType === 'o' && !participant.isFriend){
        adminUserWithoutName.push(participant);
        return;
      }

      if(participant.isFriend){
        userWithName.push(participant);
        return;
      }

      if(!participant.isFriend && isEmojiCharsOnly(participant?.userProfile?.nickName)){
        userWithEmojisOnly.push(participant);
        return;
      }

      userWithoutName.push(participant);
    });
    
    adminUserWithName = adminUserWithName.sort((a,b) => {
      const aName = a.name?.toLowerCase() || a.name;
      const bName = b.name?.toLowerCase() || b.name;
      return (aName > bName) ? 1 : ((bName > aName) ? -1 : 0);
    });
    adminUserWithoutName = adminUserWithoutName.sort((a,b) => {
      const aName = a?.userProfile?.nickName?.toLowerCase() || a?.userProfile?.nickName;
      const bName = b?.userProfile?.nickName?.toLowerCase() || b?.userProfile?.nickName;
      return (aName > bName) ? 1 : ((bName > aName) ? -1 : 0)
    });
    userWithName = userWithName.sort((a,b) => {
      const aName = a.name?.toLowerCase() || a.name;
      const bName = b.name?.toLowerCase() || b.name;
      return (aName > bName) ? 1 : ((bName > aName) ? -1 : 0);
    });
    userWithoutName = userWithoutName.sort((a,b) => {
      const aName = a?.userProfile?.nickName?.toLowerCase() || a?.userProfile?.nickName;
      const bName = b?.userProfile?.nickName?.toLowerCase() || b?.userProfile?.nickName;
      return (aName > bName) ? 1 : ((bName > aName) ? -1 : 0)
    });
    return [...localUserObj, ...adminUserWithName, ...adminUserWithoutName, ...adminUserWithEmojisOnly, ...userWithName, ...userWithoutName, ...userWithEmojisOnly];
}

export const formatMentionedUserHtmlToSpecForm = (caption) => {
    if(!caption) return caption;

    let temp = document.createElement("div");
    temp.innerHTML = caption;
    let mentionedUiElements = temp.getElementsByClassName("mentioned");
    Array.from(mentionedUiElements).forEach((e) => {
        e.innerHTML = "@[?]";
    });
    return temp.textContent || temp.innerText;
}
