import React, { Suspense } from 'react';
import ReactDOM from "react-dom/client";
import * as serviceWorker from './serviceWorker';
import SDK from './Components/SDK'
import "./assets/scss/common.scss";
import { getFromUserIdFromVcard, getLocalUserDetails } from './Helpers/Chat/User';
import { deleteItemFromLocalStorage, deleteItemFromSessionStorage, encryptAndStoreInLocalStorage, getFromLocalStorageAndDecrypt, getFromSessionStorageAndDecrypt } from './Components/WebChat/WebChatEncryptDecrypt';
import { disconnectCallConnection } from './Helpers/Call/Call';

window.addEventListener("DOMContentLoaded", function() {
  deleteItemFromLocalStorage("new_recent_chat_data");
 });

window.onbeforeunload = function() { 
  deleteItemFromSessionStorage("isLogout");
    if (getFromLocalStorageAndDecrypt("sessionId") === getFromSessionStorageAndDecrypt("sessionId")) {
      const  callConnectionData = JSON.parse(getFromLocalStorageAndDecrypt('call_connection_status'))
      if(callConnectionData && callConnectionData.from){
        let vcardData = getLocalUserDetails();
        let userJid = callConnectionData.userJid ? callConnectionData.userJid : callConnectionData.from;
        userJid = userJid.includes("@") ? userJid.split('@')[0] : userJid;
        let isUserInCall = false;
        if(!isUserInCall){
          isUserInCall = userJid === getFromUserIdFromVcard(vcardData);
        }

        if(callConnectionData.toUsers && Array.isArray(callConnectionData.toUsers) && !isUserInCall){
          isUserInCall = callConnectionData.toUsers.some(user => {
            const userJid = user.includes("@") ? user.split('@')[0] : user;
            return userJid === getFromUserIdFromVcard(vcardData);
          });
        }
        if (isUserInCall){
          console.log("call data ending call in the browser refresh");
          disconnectCallConnection()
          // SDK.endCall();
        }
      }
      deleteItemFromLocalStorage('roomName');
      deleteItemFromLocalStorage('callType');
      deleteItemFromLocalStorage('call_connection_status');
      deleteItemFromLocalStorage('connecting');
      encryptAndStoreInLocalStorage('callingComponent',false);
    } else  {
      deleteItemFromLocalStorage('roomName');
      deleteItemFromLocalStorage('callType');
      deleteItemFromLocalStorage('call_connection_status');
      deleteItemFromLocalStorage('connecting');
      encryptAndStoreInLocalStorage('callingComponent',false);
      SDK && SDK.endCall();

    }

  };

let ProviderComponent = React.lazy(() => import('./Provider/ProviderComponent'));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={
    <div className="mainLoader">
      <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve"><path fill="#4c2766cc" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50" transform="rotate(5.90184 50 50)"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform></path></svg>
    </div>
  }><ProviderComponent /></Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
