import {
  IMAGE_API_SUCCESS_DATA_UPDATE,
  IMAGE_API_DATA_REQUEST,
  IMAGE_API_DATA_CHECK_IS_THERE_OPEN_REQ,
  IMAGE_API_DATA_CHECK_IS_THERE_CLOSE_REQ
} from "../Actions/Constants";
import { binarySearch } from "../Helpers/Utility";

const initialState = {
  loading: false,
  data: [],
  error: "",
  isImageAlreadyThere: false
};

export const ImageAPIReducer = (state = initialState, action) => {
  switch (action.type) {
    case IMAGE_API_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };

    case IMAGE_API_SUCCESS_DATA_UPDATE:
      const newData = action.payload;
      const updatedData = state.data.length < 45 ?  [...state.data, ...newData.url] : [];
      return {
        ...state,
        data: updatedData,
        loading: false,
        error: ""
      };
    case IMAGE_API_DATA_CHECK_IS_THERE_CLOSE_REQ:
      return {
        ...state,
        isImageAlreadyThere: false,
        loading: false,
        error: ""
      };
    default:
      return state;
  }
};
