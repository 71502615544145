import { USER_INFO_REQUEST, USER_INFO_SUCCESS,USER_INFO_FAILURE, USER_INFO_REMOVE_SUCCESS, PROFILE_TYPE_UPDATE, RECENT_CHAT_LIST_TAB_UPDATE, UPDATE_IMPERSONATING_USER_PROFILE  } from "./Constants";


export const fetchUserInfoRequest = () => ({
    type: USER_INFO_REQUEST,
  });
  
  export const fetchUserInfoUpdate = (data) => ({
    type: USER_INFO_SUCCESS,
    payload: data,
  });
  
  export const fetchUserInfoError = (error) => ({
    type: USER_INFO_FAILURE,
    payload: error,
  });
  
  export const fetchUserInfoRemove = () => ({
    type: USER_INFO_REMOVE_SUCCESS,
  });

  export const updateProfileType = (payload) => ({
    type: PROFILE_TYPE_UPDATE,
    payload
  });

  export const updateRecentChatListTab = (payload) => ({
    type: RECENT_CHAT_LIST_TAB_UPDATE,
    payload
  });

  export const updateImpersonatingUserProfile = (payload) => ({
    type: UPDATE_IMPERSONATING_USER_PROFILE,
    payload
  });
  
  
