import { consumers } from "stream";
import { RecentChatAction } from "../../../Actions/RecentChatActions";
import Store from "../../../Store";
import SDK from "../../SDK";
  
  export const getRecentChatSDK = async () => {
    const initialData = {
      consumerChat: [],
      psychicChat: [],
      friendsChat: [],
      broadcastChat: []
    };
  
    const userJid = getCurrentUserID();
    const currentUserId = sanitizeJID(userJid);
  
    const recentChatsRes = await SDK.getRecentChats();
    if (recentChatsRes && recentChatsRes.statusCode === 200) {
      const formattedData = recentChatsRes.data.reduce((acc, val) => {
        const psychicUserId = sanitizeJID(val?.psychicUser);
        const isCurrentUser = currentUserId === psychicUserId;
        const messageTypeOption = val?.chatType === "chat" ? (val?.conversationType == "0") ? 'friendsChat' : isCurrentUser ? 'psychicChat' : 'consumerChat' : "broadcastChat";
        const targetArray = acc[messageTypeOption];
        if (targetArray) {
          targetArray.push({ ...val, conversationMessageType: messageTypeOption });
        } else {
          console.warn(`Unexpected messageTypeOption: ${messageTypeOption}`);
        }
        return acc;
      }, initialData);
  
      // console.log("recentChatsRes", recentChatsRes?.data);
      Store.dispatch(RecentChatAction(formattedData));
    }
  };

export const sanitizeJID = (Jid) => {
    return Jid?.includes("@") ? Jid?.split('@')[0] : Jid;
}
export const getCurrentUserID = () => {
    const {data: { userId }} = Store.getState() && Store.getState()?.vCardData;
    return userId;
}
export const fetchChatType = (message = {}) => {
    const { psychicUser, conversationType } = message;
    if(conversationType == '0'){
      return 'friendsChat';
    }else if(conversationType == '1'){
      const userJid = getCurrentUserID();
      const currenUserId = sanitizeJID(userJid);
      const psychicUserId = sanitizeJID(psychicUser);
      if (psychicUserId) {
          const isCurrentUser = currenUserId === psychicUserId;
          if (conversationType == "1") {
            return isCurrentUser ? 'psychicChat' : 'consumerChat';
          }
      }
    }
}