import { feedbackConstants } from '../Actions/Constants';
import { v4 as uuid } from 'uuid';
const initialState = {
    type: '',
    responseArray: [],
    messageCount: 0,
    usersList:[],
    loading: false,
    totalRecords: 0,
    error:false,
    currentPage:1,
    usersTotalRecords:'',
    usersCurrentPage:'',
    formLoading:false,
    errorElement:'',
    editErrorElement:'',
    exportFeedbackData: [],
    exportTotalRecords: 0,
    exportLoding: false,
}

const initUrl = {
    SearchUrl : ''
  }

export function feedback(state = initialState, action = {}) {
    const { feedback, messageCount, totalRecords, currentPage, type , isPageExist} = action;
    switch (action.type) {
        case feedbackConstants.FEEDBACK_REQUEST:
            return { ...state, type, loading: true, error: null };
        case feedbackConstants.FEEDBACK_GET_SUCCESS:
            return {
                ...state,
                type,
                // responseArray: (isPageExist) ? (( feedback ) ? [...state.responseArray, ...feedback] : [...state.responseArray]): (( feedback ) ? [...feedback] : []),
                responseArray: (isPageExist)
                ? ((feedback)
                  ? [
                      ...new Map(
                        [...state.responseArray, ...feedback].map((item) => [item.id, item])
                      ).values()
                    ]
                  : [...state.responseArray])
                : ((feedback)
                  ? [
                      ...new Map(
                        feedback.map((item) => [item.id, item])
                      ).values()
                    ]
                  : []),
                messageCount: messageCount || state.messageCount,
                totalRecords,
                currentPage: (currentPage) ? currentPage : state.currentPage,
                loading: false,
                formLoading:false,
                error: false,
            }
        case feedbackConstants.DELETE_FEEDBACK_SUCCESS:
            return {
                ...state,
                type,
                responseArray: state.responseArray.filter(( teamObj ) => {
                    return action.updateObject.ids.indexOf(teamObj.id)
                }),
                totalRecords: state.totalRecords - action.updateObject.updateRecords,
                loading: false,
                error:false
            }
        case feedbackConstants.FEEDBACK_FAILURE:
            return {
                ...state,
                type, 
                loading: false,   
                error:true
            }
        case feedbackConstants.FEEDBACK_FORM_REQUEST:
            return { 
                ...state, 
                type, 
                loading: true, 
                formLoading: true, 
                error: true, 
                errorElement:'', 
                editErrorElement:'', 
            };
        case feedbackConstants.UPDATE_FEEDBACK_SUCCESS:
            return {
                ...state,
                type, 
                loading: false,
                formLoading:false,
                error: false,
                editErrorElement:'',
            }
        case feedbackConstants.FEEDBACK_SAVE_FORM_FAILURE:
            return {
                ...state,
                type, 
                loading: false,  
                formLoading:false, 
                error:true,
                editErrorElement: action.error,
            }
        default:
            return state;
    }
}

const conversationInitialState = {
    id: '',
    conversationId: 0,
    conversationArray: [],
    totalRecords: 0,
    conversationLoading: false,
    conversationError: false,
    conversationErrorMessage: '',
    feedbackdetails: {},
    updateType: ''
}

const concatMessageArray = (activeData, reducerData) => {
    const updateMessage = [
        ...activeData, // new data
        ...reducerData // already existing data
    ]
    let messageArray = updateMessage.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
            return acc.concat([ current ]);
        } else {
            return acc;
        }
    }, []).sort((a, b) => a.id > b.id ? 1 : a.id < b.id ? -1 : 0);
    return messageArray;
}
const concatNewMessageObj = (activeObj, reducerData) => {
    const updateMessage = [
        ...reducerData,
        activeObj,
    ]
    return updateMessage
}

const updateMessage = (newMessage, messages) => {
    const { id } = newMessage;
    let isExist = messages.find(msg => msg.id === id);
    if(!isExist) {
        // messages.push(newMessage);
    }
    return messages;
}

export function feedbackConversation(state = conversationInitialState, action = {}) {
    const { id, type, messages = [], error, totalRecords, message, feedbackdetails, newMsgObj } = action;
    switch (type) {
        case feedbackConstants.CONVERSATION_REQUEST:
            return { 
                ...state, 
                id,
                loading: true,
                feedbackdetails: {},
                conversationLoading: true, 
                conversationError: null, 
                conversationErrorMessage: '' 
            };
        case feedbackConstants.CONVERSATION_GET_SUCCESS:
            return {
                ...state,
                id,
                conversationArray: concatMessageArray(messages, state.conversationArray),
                totalRecords,
                loading: false,
                conversationLoading: false,
                conversationError: false,
                conversationErrorMessage: '',
                feedbackdetails: feedbackdetails ? feedbackdetails : { }
            }
     case feedbackConstants.FEEDBACK_NEW_MESSAGE_ADDED_SUCCESS:
                return {
                    ...state,
                id,
                conversationArray: concatNewMessageObj(newMsgObj, state.conversationArray),
                totalRecords,
                loading: false,
                conversationLoading: false,
                conversationError: false,
                conversationErrorMessage: '',
                feedbackdetails: feedbackdetails ? feedbackdetails : { }
                }
        case feedbackConstants.CONVERSATION_FAILURE:
            return {
                ...state,
                id,
                conversationLoading: false, 
                loading: false,
                conversationError: true,
                conversationErrorMessage: error,
            }
        case feedbackConstants.REMOVE_CONVERSATION:
            return {
                ...state,
                id,
                conversationArray: [],
                totalRecords: 0,
                conversationLoading: false,
                conversationError: false,
                conversationErrorMessage: ''
            }
        case feedbackConstants.UPDATE_CONVERSATION:
            return {
                ...state,
                id,
                updateType: type,
                conversationLoading: false,
                conversationError: false,
                conversationErrorMessage: ''
            }
            case feedbackConstants.UPDATE_CONVERSATION_EMPTY:
                return {
                    ...state,
                    id,
                    updateType: '',
                    conversationLoading: false,
                    conversationError: false,
                    conversationErrorMessage: ''
                }
        default:
            return state;
    }
}
export function manageFeedbackSearchURL(state = initUrl, action = {}) {
    const { SearchUrl, typeId } = action;
    switch (action.type) {
        case feedbackConstants.FEEDBACK_SEARCH_URL_RETAIN:
          return {
          ...state, 
              SearchUrl,
              typeId
          };
      default:
        return state;
    }
  }

  const adminConversationInitialState = {
    id: '',
    feedbackMessageArray: [],
    feedbackMessageCount: 0,
    feedbackMessageTotalRecords: 0,
    loading: false,
    error:false,
    currentPage:1,
    formLoading:false,
    errorElement:'',
    editErrorElement:'',
    popUpOpen: false,
    addEditorPopup: false,
  } 

const concatAdminMessageArray = (activeData, reducerData) => {
    // Check if there are any elements in activeData or reducerData
    if (activeData.length === 0 || reducerData.length === 0) {
        return activeData;
    }

    // Extract feedbackId from the first elements of both arrays for comparison
    const newFeedbackId = activeData[ 0 ].feedbackId;
    const existingFeedbackId = reducerData[ 0 ].feedbackId;

    // If feedbackIds do not match, replace the entire array
    if (newFeedbackId !== existingFeedbackId) {
        return activeData;
    }

    // Create a map from the existing reducer data for quick lookup
    const messageMap = new Map(reducerData.map(item => [ item.id, item ]));

    // Merge the new active data into the map
    activeData.forEach(item => {
        // Check if the message already exists in the map
        if (messageMap.has(item.id)) {
            // Update the existing message with the new data
            const existingMessage = messageMap.get(item.id);
            if (JSON.stringify(existingMessage) !== JSON.stringify(item)) {
                messageMap.set(item.id, { ...existingMessage, ...item });
            }
        } else {
            // Add the new message to the map
            messageMap.set(item.id, item);
        }
    });

    // Convert the map back to an array and sort by `createdAt` in descending order
    const updatedMessages = Array.from(messageMap.values()).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return updatedMessages;
};

  export function manageAdminFeedbackMessage(state = adminConversationInitialState, action = {}) {
    const { typeId, adminMessages } = action;
    switch (action.type) {
        case feedbackConstants.SEND_ADMIN_FEEDBACK_MESSAGE_REQUEST:
          return {
          ...state, 
            loading: true,
            addEditorPopup: true
          };
          case feedbackConstants.SEND_ADMIN_FEEDBACK_MESSAGE_SUCCESS:
          return {
          ...state, 
            id: uuid(),
            addEditorPopup: false
          };
          case feedbackConstants.SEND_ADMIN_FEEDBACK_MESSAGE_FAILURE:
          return {
          ...state, 
            loading: false,
            id: uuid(),
            addEditorPopup: true
          };
          case feedbackConstants.UPDATE_ADMIN_FEEDBACK_MESSAGE_REQUEST:
          return {
          ...state, 
            loading: true,
            popUpOpen: true
            
          };
          case feedbackConstants.UPDATE_ADMIN_FEEDBACK_MESSAGE_SUCCESS:
          return {
          ...state, 
            id: uuid(),
            popUpOpen: false
            
          };
          case feedbackConstants.FETCH_ADMIN_FEEDBACK_MESSAGE_REQUEST:
            return {
            ...state, 
              id: uuid(),
            };
          case feedbackConstants.UPDATE_ADMIN_FEEDBACK_MESSAGE_FAILURE:
          return {
          ...state, 
            id: uuid(),
            loading: false,
            popUpOpen: true
            
          };
          case feedbackConstants.FETCH_ADMIN_FEEDBACK_MESSAGE_SUCCESS:
          return {
          ...state, 
              id: uuid(),
              loading: false,
              feedbackMessageArray: concatAdminMessageArray(adminMessages.data, state.feedbackMessageArray),
              feedbackMessageCount: adminMessages.count,
              feedbackMessageTotalRecords: adminMessages.totalRecords
          };
          case feedbackConstants.FETCH_ADMIN_FEEDBACK_MESSAGE_FAILURE:
          return {
          ...state, 
              id: uuid(),
              loading: false,
          };
      default:
        return state;
    }
  }