import axios from 'axios';
import { toast } from 'react-toastify';

const authToken = localStorage.getItem('token') !== null ? localStorage.getItem('token') : process.env.REACT_APP_AUTH_SECRET_KEY;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
    'Authorization': authToken,
  },
});

instance.interceptors.request.use(
  (config) => config,
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {

    if(response.data?.status == 200){
      return response.data;
    }

    if (response.data?.status == 401) {
      //refresh token chenge fucntion
      return;
    }

    if(response.data?.status != 200){

      if (response.config.onError) {
        response.config.onError(response.data);
        return {data:undefined};
      }else {
        toast.error(response.data?.message)
        return {data:undefined};
      }
    }
    
    throw Promise.reject('Check the axios model');
  },
  (error) => {
    console.error('Response Error Interceptor:', error);
    return Promise.reject(error);
  }
);

export default instance;