import { CONFRENCE_POPUP_STATUS, RESET_CONFRENCE_POPUP_STATUS, UPDATE_CONFERENCE_ALL } from '../Actions/Constants';

const initialState = {
    id: null,
    data: {
        showCalleComponent:false,
        showComponent:false,
        showStreamingComponent:false,
        showComponentAsFLoat:false,
        callStatusText:"",
        callInitiator:false,
        timerOfAndSave: false,
        lastCutTime:null,
        isPaid:"2",
        readingAmount:"",
        psychicCallType: "",
        availableTime: "00",
        callPopUpShownOtherPlace:'',
        roster:null,
        floatComponentPositionUp: 0,
        typeOfPopUp:'',
        callComponentSize:'medium',
        callTimeOn :false,  
        forceCalculateTimeFormat:'', 
        isFriendCall:false,    
        additionalData: {} 
    }
  };

export function showConfrenceReducer(state = initialState, action = {}) {
    
    if (action.type === CONFRENCE_POPUP_STATUS) {
        state.id = action.payload.id;
        state.data = {...initialState.data, ...state.data, ...action.payload?.data}
        //  = {...initialState, ...state, ...action.payload}
        return {...state};
    } else if (action.type === RESET_CONFRENCE_POPUP_STATUS) {
        return initialState;
    } else if(action.type === UPDATE_CONFERENCE_ALL) {
        state.data = {...state.data, ...action.payload.data};
        return state
    }
    return state;
}
